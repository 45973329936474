import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components';
import { Layout, PostCard, Pagination, Container, AuthorCard } from '../components/common';
import { MetaData } from '../components/common/meta'
import saveEmail from '../helpers/saveEmail';

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const PostFeed = styled.section`
    display: grid;
    justify-content: space-between;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 680px) {
        grid-template-columns: 1fr;
    }
`;
const Author = ({ data, location, pageContext }) => {
    const author = data.ghostAuthor
    const posts = data.allGhostPost.edges
    
     //state
     const [email, setEmail] = useState('');
     const [confirmed, setConfirmed] = useState(false);
     const [error, setError] = useState(false);

    
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
            />
            <Layout
                headerBackText='Blog'
                headerBackLink='/'
                headerButtonText='Get a demo'
                headerButtonLink='/'
                onFooterEmailChange={value => setEmail(value)}
                footerEmail={email}
                footerError={error}
                footerSubmit={(e) => saveEmail(e, email, setConfirmed, setError)}
                footerEmailConfirmed={confirmed}
            >
                <Container>
                    <AuthorCard author={author} />
                    <PostFeed>
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </PostFeed>
                    <Pagination pageContext={pageContext} />
                </Container>
            </Layout>
        </>
    )
}

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Author

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {authors: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`